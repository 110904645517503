import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { alluniforms, toggleStatus, createUniform, reset } from "../../../features/uniforms/uniformsSlice";
import { toast } from "react-toastify";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import CustomeModal from "../../components/customeModal/CustomeModal";
import noImage from "../../../images/noimage.png"

function Uniforms() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [description, setDescription] = useState("");
  const [jobrole, setJobRole] = useState("");
  const [icon, setIcon] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEditShow = () => setShowEdit(true);
  const handleEditClose = () => setShowEdit(false);

  const { uniforms, isLoading, isError, isSuccess, message } = useSelector((state) => state.uniforms);


  const [uniformModalOpen, setUniformModalOpen] = useState(false)


  //   const handleShowUniformModal = (templateId) => {
  //     setUniformModalOpen(true)
  //     dispatch(())
  //  }
  //  const handleCloseUniformModal = () => {
  //     setUniformModalOpen(false)
  //  }


  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createUniform({ description, jobrole, icon, pageno: pageNumber === 0 ? 1 : pageNumber }));
    setShow(false);
    // toast.success(message);
  };

  useEffect(() => {
    if (isError) {
      // toast.error(message);
    }
    if (isSuccess) {
      // toast.success(message);
    }
    if (!user) {
      navigate("/login");
    }
    dispatch(alluniforms(1));
    return () => reset();
  }, [user, navigate, isError, isSuccess, message, dispatch]);

  const [pageNumber, setPageNumber] = useState(0);

  const userPerPage = 10;
  const pageVisted = pageNumber;
  let pageCount;
  const total_pages = uniforms?.total_pages
  const UnifromsData = uniforms.body?.uniforms
  const jobroles = uniforms.body?.jobroles

  const getUniforms = () => {
    pageCount = total_pages;


    const truncateString = (text, maxLength) => {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    return UnifromsData?.map((data, index) => {
      return (
        <tr role="row" className="odd" key={index}>
          <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0">
                  {/* <img src={data.image} height={50} width={50} alt="" onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${process.env.REACT_APP_IMAGEBASEURL}noImg.png`;
                  }} className="rounded-circle" /> */}
                  <img
                    src={data.image}
                    alt="User Profile"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = noImage;
                    }}
                    height={50}
                    width={50}
                    className="rounded-circle"
                  />
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0">
                  {data.jobrole.title}
                </h6>
              </div>
            </div>
          </td>
          {/* <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0">
                  <img src={data.jobrole.icons} height={50} width={50} alt="" onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = `${process.env.REACT_APP_IMAGEBASEURL}noImg.png`;
                  }} />
                </h6>
              </div>
            </div>
          </td> */}
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0">
                  {data.user.user_type === 3 ? "Admin" : "Employer"}
                </h6>
              </div>
            </div>
          </td>

          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <div className="fs-16 mb-0">
                  {data.status === true ? <button onClick={() => {
                    dispatch(toggleStatus({ uniform_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                  }} className='btn btn-rounded btn-light text-success btn-sm'>Active</button> : <button onClick={() => {
                    dispatch(toggleStatus({ uniform_id: data._id, pageno: pageNumber === 0 ? 1 : pageNumber }));
                  }} className='btn btn-rounded btn-light text-danger btn-sm'>Inactive</button>}
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body">
                <h6 className="fs-16 mb-0 text-nowrap"
                  title={data.description.length > 20 ? data.description : ''}>
                  {truncateString(data.description, 20)}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0 mx-2">
                  <button onClick={
                    () => { handleEditShow() }
                  } ><svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.3307 6.66665H11.9974C10.1306 6.66665 9.19713 6.66665 8.48409 7.02996C7.85689 7.34954 7.34695 7.85948 7.02737 8.48668C6.66406 9.19972 6.66406 10.1331 6.66406 12V28C6.66406 29.8668 6.66406 30.8002 7.02737 31.5133C7.34695 32.1405 7.85689 32.6504 8.48409 32.97C9.19713 33.3333 10.1306 33.3333 11.9974 33.3333H27.9974C29.8642 33.3333 30.7977 33.3333 31.5107 32.97C32.1379 32.6504 32.6478 32.1405 32.9674 31.5133C33.3307 30.8002 33.3307 29.8668 33.3307 28V20.8333M25.8307 9.16665L30.5448 13.8807M17.9353 17.0621L29.0157 5.98168C30.3174 4.67994 32.428 4.67993 33.7297 5.98168C35.0315 7.28343 35.0315 9.39398 33.7297 10.6957L22.2931 22.1323C21.0237 23.4017 20.389 24.0365 19.6661 24.5412C19.0244 24.9892 18.3323 25.3604 17.604 25.647C16.7836 25.97 15.9037 26.1475 14.1439 26.5026L13.3307 26.6667L13.4098 26.1131C13.6897 24.1541 13.8296 23.1746 14.1479 22.2602C14.4303 21.4487 14.8161 20.6771 15.2958 19.9643C15.8364 19.161 16.536 18.4613 17.9353 17.0621Z" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </button>
                </h6>
              </div>
            </div>
          </td>

        </tr>
      );
    });
  };

  const getJobroles = () => {
    return jobroles?.map((jdata, jindex) => {
      return (
        <option value={jdata._id} key={jindex}>
          {jdata.title}
        </option>
      )
    })
  }

  const changePage = async (data) => {
    setPageNumber(data.selected);
    dispatch(alluniforms(data.selected + 1))
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <h1 className="text-black fs-35 font-w600 mb-3">
                      Uniforms
                    </h1>
                    <h6 className="fs-16 mb-1">
                      {userPerPage} Rows per page
                    </h6>
                  </div>
                  <div>
                    <button className="btn btn-rounded btn-md btn-success float-right" onClick={handleShow}>+  Add Uniform</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="ID: activate to sort column ascending"
                              >
                                Id
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                                style={{ paddingLeft: '20px' }}
                              >
                                Image
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Job Role
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Owner
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                                style={{ paddingLeft: '23px' }}
                              >
                                Status
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                                style={{ width: '250px' }}
                              >
                                Description
                              </th>
                              <th
                                className="sorting"
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getUniforms()}</tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <CustomeModal
            title="Uniform"
            show={uniformModalOpen}
            onHide={handleCloseUniformModal}
            content={selectedUniform ? uniformModalContent : "Loading..."}
         /> */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Uniform</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="form-group" onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12 my-3">
                      <textarea required className="form-control rounded-4" onChange={(e) => setDescription(e.target.value)} value={description} autoFocus cols="10" rows="5" placeholder="Description"></textarea>
                    </div>
                    <div className="col-12 my-3">
                      <input type="file" accept="image/*" required onChange={(e) => setIcon(e.target.files[0])} className="form-control rounded-4" />
                    </div>
                    <div className="col-12 my-2">
                      <select className="form-control rounded-4" onChange={(e) => setJobRole(e.target.value)} value={jobrole}>
                        <option value="" required defaultValue disabled>Select Jobrole</option>
                        {getJobroles()}
                      </select>
                    </div>
                  </div>
                  <Modal.Footer className="my-5">
                    <Button type="submit" variant="success" className="btn-block rounded-4">
                      Save Changes
                    </Button>
                    <Button className="btn-block rounded-4" onClick={handleClose} style={{ backgroundColor: '#21127b' }}>
                      Close
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal.Body>
            </Modal>
            <Modal show={showEdit} onHide={handleEditClose}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Uniform</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="form-group" onSubmit={onSubmit}>
                  <div className="row">
                    <div className="col-12 my-3">

                      <textarea required className="form-control rounded-4" onChange={(e) => setDescription(e.target.value)} value={description} autoFocus cols="10" rows="5" placeholder="Description"></textarea>
                    </div>
                    <div className="col-12 my-3">
                      {/* <label htmlFor="">Image</label> */}
                      <input type="file" accept="image/*" required onChange={(e) => setIcon(e.target.files[0])} className="form-control rounded-4" />
                    </div>
                    <div className="col-12 my-2">
                      <select className="form-control rounded-4" onChange={(e) => setJobRole(e.target.value)} value={jobrole}>
                        <option value="" required defaultValue disabled>Select Jobrole</option>
                        {getJobroles()}
                      </select>
                    </div>
                  </div>
                  <Modal.Footer className="my-5">
                    <Button type="submit" variant="success" className="btn-block rounded-4">
                      Save Changes
                    </Button>
                    <Button className="btn-block rounded-4" onClick={handleClose} style={{ backgroundColor: '#21127b' }}>
                      Close
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </span>
      )}
    </>
  );
}

export default Uniforms;
