import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import dateFormat from "dateformat";
import { allExpenses, createExpense, reset } from "../../../features/expenses/expensesSlice";
import { toast } from "react-toastify";
import Nav from "../../layouts/nav";
import ReactPaginate from "react-paginate";
import Spinner from "../../components/Spinner";
import { Form } from "react-bootstrap";


const getDate = (date) => {
  return dateFormat(date, "yyyy-mm-dd ");
};


function Expenses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [description, setDescription] = useState("");
  const [category_id, setCategory_id] = useState("");
  const [amount, setAmount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { expenses, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.expenses
  );



  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(createExpense({ description, category_id, amount, pageno: pageNumber === 0 ? 1 : pageNumber }));
    setShow(false);
    // toast.success(message);
  };

  useEffect(() => {
    if (isError) {
      // toast.error(message);
    }
    if (isSuccess) {
      // toast.success(message);z
    }
    if (!user) {
      navigate("/login");
    }
    dispatch(allExpenses(1));
    return () => reset();
  }, [user, navigate, isError, isSuccess, message, dispatch]);
  const [pageNumber, setPageNumber] = useState(0);
  const userPerPage = 10;
  const pageVisted = pageNumber;
  // let workers;
  let pageCount;
  const total_pages = expenses?.total_pages
  const expenseData = expenses.body?.expenses
  const categories = expenses.body?.expenseCategories
  const getExpensesFun = () => {


    const formatName = (name) => {
      if (!name) return '';
      const nameParts = name.split(' ');
      if (nameParts.length > 1) {
        return `${nameParts[0][0]}.${nameParts.slice(-1)}`;
      }
      return name;
    };

    const truncateString = (text, maxLength) => {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    };

    pageCount = total_pages;
    return expenseData?.map((data, index) => {
      return (
        <tr role="row" className="odd" key={index}>
          <td>{index + 1 + (pageNumber === 0 ? 0 : pageNumber * 10)}.</td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6
                  className="fs-16 mb-0 text-capitalize"
                  title={data?.category?.title?.length > 10 ? data?.category?.title : ''}
                >
                  {truncateString(formatName(data?.category?.title), 10)}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0 ">
                  {data.category.title}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body">
                <h6 className="fs-16 mb-0 ">
                  ${data.amount}.00
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0 ">
                  {data.user.user_type === 3 ? `${data.user.name}` : `SubAdmin ${data.user.name}`}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0 ">
                  {getDate(data.createdAt)}
                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body ">
                <h6 className="fs-16 mb-0 text-nowrap"
                  title={data?.description.length > 20 ? data?.description : ''}>
                  {truncateString(data?.description, 20)}

                </h6>
              </div>
            </div>
          </td>
          <td>
            <div className="media">
              <div className="media-body text-nowrap">
                <h6 className="fs-16 mb-0 mx-4">
                  <svg width="25" height="25" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.0026 27.6667L21.3359 31L28.0026 24.3333M14.6693 1H6.66927C4.80243 1 3.86901 1 3.15597 1.36331C2.52876 1.68289 2.01883 2.19282 1.69925 2.82003C1.33594 3.53307 1.33594 4.46649 1.33594 6.33333V25.6667C1.33594 27.5335 1.33594 28.4669 1.69925 29.18C2.01883 29.8072 2.52876 30.3171 3.15597 30.6367C3.86901 31 4.80243 31 6.66927 31H12.1693M14.6693 1L24.6693 11M14.6693 1V8.33333C14.6693 9.26675 14.6693 9.73346 14.8509 10.09C15.0107 10.4036 15.2657 10.6586 15.5793 10.8183C15.9358 11 16.4025 11 17.3359 11H24.6693M24.6693 11V18.3333M8.0026 24.3333H12.1693M8.0026 17.6667H18.0026M8.0026 11H9.66927" stroke="#00B094" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </h6>
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  const getCategories = () => {
    return categories?.map((jdata, jindex) => {
      return (
        <option value={jdata._id} key={jindex}>
          {jdata.title}
        </option>
      )
    })
  }

  const changePage = async (data) => {
    setPageNumber(data.selected);
    dispatch(getExpensesFun(data.selected + 1))
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <span>
          <Nav />
          <div className="content-body">
            <div className="container-fluid">
              <div className="h-80">
                <div className="mb-4 d-flex">
                  <div className="col-xl-6 col-xs-6">
                    <h1 className="text-black fs-35 font-w600">
                      Expenses
                    </h1>
                    <h6 className="fs-16 mb-1">
                      {userPerPage} Rows per page
                    </h6>
                  </div>
                  <div className="col-xl-6 col-xs-6 text-end">
                    <Link to="/expense-category">

                      <button className="btn btn-rounded btn-md text-white " style={{ backgroundColor: '#1f1179' }}> Categories</button>
                    </Link>
                    <button className="btn btn-rounded btn-md btn-success mx-3" onClick={handleShow}>+ Add Expense</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="table-responsive">
                      <div
                        id="example5_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <table
                          className="table display mb-4 dataTablesCard card-table dataTable no-footer"
                          id="example5"
                          role="grid"
                          aria-describedby="example5_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="ID: activate to sort column ascending"
                              >
                                Id
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Name
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Category
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Amount
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                              >
                                Owner
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              >
                                Date
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Date Applied: activate to sort column ascending"
                                style={{ width: '250px' }}
                              >
                                Description
                              </th>
                              <th
                                className="sorting "
                                tabIndex={0}
                                aria-controls="example5"
                                rowSpan={1}
                                colSpan={1}
                                aria-label="Status: activate to sort column ascending"
                              // style={{ paddingLeft: '23px'}}
                              >
                                Attachments
                              </th>
                            </tr>
                          </thead>
                          <tbody>{getExpensesFun()}</tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-between pb-2">
                          <div
                            className="dataTables_info"
                            id="example5_info"
                            role="status"
                            aria-live="polite"
                          >
                            Showing {pageVisted + 1}
                            of {total_pages} pages
                          </div>
                          <div
                            className="dataTables_paginate paging_simple_numbers"
                            id="example5_paginate"
                          >
                            <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={"paginationBttns"}
                              previousLinkClassName={
                                "paginate_button previous previousBttn"
                              }
                              nextLinkClassName={
                                "paginate_button next nextBttn"
                              }
                              pageLinkClassName={"paginate_button mr-1 ml-1"}
                              disabledClassName={"paginationDisabled"}
                              activeClassName={"paginationActive"}
                              forcePage={pageNumber}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Add Expense</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className="form-group" onSubmit={onSubmit}>
                  <textarea
                    className="form-control rounded-3 my-4"
                    placeholder="Decription"
                    rows={5}
                    autoFocus
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    required
                  />

                  <input
                    className="form-control rounded-3 my-4"
                    type="name"
                    placeholder="Enter Your Name"
                  />
                  <input
                    className="form-control rounded-3 my-4"
                    type="number"
                    placeholder="Enter Your Amount"
                    onChange={(e) => setAmount(e.target.value)}
                    value=""
                  />

                  <Form.Select className="form-control rounded-3" onChange={(e) => setCategory_id(e.target.value)} value={category_id}>
                    <option value="" required defaultValue disabled>Select Category</option>
                    {getCategories()}
                  </Form.Select>
                  <Modal.Footer>
                    <Button type="submit" variant="success" className="btn-block rounded-4">
                      Save Changes
                    </Button>
                    <Button className="btn-block rounded-4" onClick={handleClose} style={{ backgroundColor: '#21117c' }}>
                      Close
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        </span>
      )}
    </>
  );
}

export default Expenses;
