import React, { useState, useEffect } from "react";
import Navigation from "../components/navigation";
import { Header } from "../components/header";
import { Features } from "../components/features";
import { LearnMore } from "../components/learnMore";
import { About } from "../components/about";
import { Services } from "../components/services";
import { Contact } from "../components/contact";
import { TopRated } from "../components/topRated";
import JsonData from "../data/data.json";
import "../assets/css/style.css";

function LandingPage() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <>
      <Navigation />
      <Header data={landingPageData.Header} />
      <LearnMore data={landingPageData.LearnMore} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <TopRated />
      <Contact data={landingPageData.Contact} />
    </>
  )
}

export default LandingPage; 