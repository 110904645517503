import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Logo1 from '../assets/img/Logo1.png';
import Logo2 from '../assets/img/Logo2.png';

const Navigation = () => {
  const navLinkStyle = {
    fontSize: '16px',
    marginRight: '50px',
    textDecoration: 'none',
    color: '#000',
    textTransform: 'uppercase',
  };

  const navLinkHoverStyle = {
    textDecoration: 'underline',
    color: '#007bff',
  };

  return (
    <Navbar bg="light" expand="lg" className="justify-content-center sticky-top">
      <Container>
        <Navbar.Brand href="/">
          <div className="d-flex align-items-center">
            <img
              src={Logo1}
              height="50"
              className="d-inline-block align-top"
              alt="Logo"
            />
            <img
              src={Logo2}
              height="50"
              className="d-inline-block align-top mr-5"
              alt="Logo"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#LearnMore" style={navLinkStyle} activeStyle={navLinkHoverStyle}>
              Learn More
            </Nav.Link>
            <Nav.Link href="#features" style={navLinkStyle} activeStyle={navLinkHoverStyle}>
              Features
            </Nav.Link>
            <Nav.Link href="#about" style={navLinkStyle} activeStyle={navLinkHoverStyle}>
              About
            </Nav.Link>
            <Nav.Link href="#services" style={navLinkStyle} activeStyle={navLinkHoverStyle}>
              Services
            </Nav.Link>
            <Nav.Link href="#contact" style={navLinkStyle} activeStyle={navLinkHoverStyle}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;