export const Icon01 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 8.72727C0 3.90733 3.90733 0 8.72727 0H51.2727C56.0927 0 60 3.90733 60 8.72727V51.2727C60 56.0927 56.0927 60 51.2727 60H8.72727C3.90733 60 0 56.0927 0 51.2727V8.72727Z"
         fill="#3144F3"
      />
      <path
         d="M15.4662 15.4665C17.3565 13.5761 19.6007 12.0766 22.0705 11.0536C24.5403 10.0305 27.1875 9.50399 29.8608 9.50399C32.5342 9.50399 35.1813 10.0305 37.6512 11.0536C40.121 12.0766 42.3652 13.5761 44.2555 15.4665C46.1459 17.3568 47.6453 19.6009 48.6684 22.0708C49.6914 24.5406 50.218 27.1878 50.218 29.8611C50.218 32.5345 49.6914 35.1816 48.6684 37.6515C47.6453 40.1213 46.1458 42.3655 44.2555 44.2558L37.0582 37.0585C38.0033 36.1133 38.7531 34.9912 39.2646 33.7563C39.7761 32.5214 40.0394 31.1978 40.0394 29.8611C40.0394 28.5245 39.7761 27.2009 39.2646 25.966C38.7531 24.731 38.0033 23.609 37.0582 22.6638C36.113 21.7186 34.9909 20.9689 33.756 20.4574C32.5211 19.9458 31.1975 19.6826 29.8608 19.6826C28.5242 19.6826 27.2006 19.9458 25.9657 20.4574C24.7307 20.9689 23.6087 21.7186 22.6635 22.6638L15.4662 15.4665Z"
         fill="#8FD7FF"
      />
      <path
         d="M15.4661 44.2558C11.6484 40.4381 9.50365 35.2601 9.50365 29.8611C9.50365 24.462 11.6484 19.2841 15.4661 15.4664C19.2838 11.6487 24.4617 9.50395 29.8608 9.50395C35.2598 9.50394 40.4378 11.6487 44.2555 15.4664L37.0581 22.6637C35.1493 20.7549 32.5603 19.6825 29.8608 19.6825C27.1613 19.6825 24.5723 20.7549 22.6635 22.6638C20.7546 24.5726 19.6822 27.1616 19.6822 29.8611C19.6822 32.5606 20.7546 35.1496 22.6635 37.0584L15.4661 44.2558Z"
         fill="white"
      />
   </svg>
);
export const Icon02 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#F39A31"
      />
      <path
         d="M16.0462 15.4665C17.9508 13.5761 20.2118 12.0766 22.7002 11.0536C25.1886 10.0305 27.8556 9.50399 30.5491 9.50399C33.2425 9.50399 35.9095 10.0305 38.3979 11.0536C40.8864 12.0766 43.1474 13.5761 45.0519 15.4665C46.9564 17.3568 48.4672 19.601 49.4979 22.0708C50.5287 24.5406 51.0592 27.1878 51.0592 29.8611C51.0592 32.5345 50.5287 35.1816 49.4979 37.6515C48.4672 40.1213 46.9564 42.3655 45.0519 44.2558L37.8005 37.0585C38.7528 36.1133 39.5081 34.9912 40.0235 33.7563C40.5389 32.5214 40.8041 31.1978 40.8041 29.8611C40.8041 28.5245 40.5389 27.2009 40.0235 25.966C39.5081 24.731 38.7528 23.609 37.8005 22.6638C36.8482 21.7186 35.7177 20.9689 34.4735 20.4574C33.2293 19.9458 31.8958 19.6826 30.5491 19.6826C29.2024 19.6826 27.8688 19.9458 26.6246 20.4574C25.3804 20.9689 24.2499 21.7186 23.2977 22.6638L16.0462 15.4665Z"
         fill="white"
      />
      <path
         d="M16.0462 44.2558C12.1999 40.4381 10.039 35.2601 10.039 29.8611C10.039 24.462 12.1999 19.2841 16.0462 15.4664C19.8926 11.6487 25.1094 9.50395 30.5491 9.50395C35.9887 9.50395 41.2055 11.6487 45.0519 15.4664L37.8005 22.6638C35.8773 20.7549 33.2689 19.6825 30.5491 19.6825C27.8293 19.6825 25.2208 20.7549 23.2977 22.6638C21.3745 24.5726 20.294 27.1616 20.294 29.8611C20.294 32.5606 21.3745 35.1496 23.2977 37.0584L16.0462 44.2558Z"
         fill="white"
      />
   </svg>
);
export const Icon03 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#D24CC5"
      />
      <path
         d="M16.0462 15.4665C17.9508 13.5761 20.2118 12.0766 22.7002 11.0536C25.1886 10.0305 27.8556 9.50399 30.5491 9.50399C33.2425 9.50399 35.9095 10.0305 38.3979 11.0536C40.8864 12.0766 43.1474 13.5761 45.0519 15.4665C46.9564 17.3568 48.4672 19.601 49.4979 22.0708C50.5287 24.5406 51.0592 27.1878 51.0592 29.8611C51.0592 32.5345 50.5287 35.1816 49.4979 37.6515C48.4672 40.1213 46.9564 42.3655 45.0519 44.2558L37.8005 37.0585C38.7528 36.1133 39.5081 34.9912 40.0235 33.7563C40.5389 32.5214 40.8041 31.1978 40.8041 29.8611C40.8041 28.5245 40.5389 27.2009 40.0235 25.966C39.5081 24.731 38.7528 23.609 37.8005 22.6638C36.8482 21.7186 35.7177 20.9689 34.4735 20.4574C33.2293 19.9458 31.8958 19.6826 30.5491 19.6826C29.2024 19.6826 27.8688 19.9458 26.6246 20.4574C25.3804 20.9689 24.2499 21.7186 23.2977 22.6638L16.0462 15.4665Z"
         fill="white"
      />
      <path
         d="M16.0462 44.2558C12.1999 40.4381 10.039 35.2601 10.039 29.8611C10.039 24.462 12.1999 19.2841 16.0462 15.4664C19.8926 11.6487 25.1094 9.50395 30.5491 9.50395C35.9887 9.50395 41.2055 11.6487 45.0519 15.4664L37.8005 22.6638C35.8773 20.7549 33.2689 19.6825 30.5491 19.6825C27.8293 19.6825 25.2208 20.7549 23.2977 22.6638C21.3745 24.5726 20.294 27.1616 20.294 29.8611C20.294 32.5606 21.3745 35.1496 23.2977 37.0584L16.0462 44.2558Z"
         fill="white"
      />
   </svg>
);
export const Icon04 = (
   <svg
      className="ml-3"
      width="61"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 8.72727C0 3.90733 3.93669 0 8.79285 0H51.658C56.5141 0 60.4508 3.90733 60.4508 8.72727V51.2727C60.4508 56.0927 56.5141 60 51.658 60H8.79285C3.93669 60 0 56.0927 0 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 8.72727C0 3.90733 3.93669 0 8.79285 0H51.658C56.5141 0 60.4508 3.90733 60.4508 8.72727V51.2727C60.4508 56.0927 56.5141 60 51.658 60H8.79285C3.93669 60 0 56.0927 0 51.2727V8.72727Z"
         fill="#52B056"
      />
      <path
         d="M15.5824 15.4665C17.4869 13.5761 19.7479 12.0766 22.2363 11.0536C24.7247 10.0305 27.3918 9.50399 30.0852 9.50399C32.7786 9.50399 35.4457 10.0305 37.9341 11.0536C40.4225 12.0766 42.6835 13.5761 44.588 15.4665C46.4926 17.3568 48.0033 19.601 49.0341 22.0708C50.0648 24.5406 50.5953 27.1878 50.5953 29.8611C50.5953 32.5345 50.0648 35.1816 49.0341 37.6515C48.0033 40.1213 46.4926 42.3655 44.588 44.2558L37.3366 37.0585C38.2889 36.1133 39.0443 34.9912 39.5596 33.7563C40.075 32.5214 40.3403 31.1978 40.3403 29.8611C40.3403 28.5245 40.075 27.2009 39.5596 25.966C39.0443 24.731 38.2889 23.609 37.3366 22.6638C36.3844 21.7186 35.2538 20.9689 34.0096 20.4574C32.7654 19.9458 31.4319 19.6826 30.0852 19.6826C28.7385 19.6826 27.405 19.9458 26.1608 20.4574C24.9166 20.9689 23.7861 21.7186 22.8338 22.6638L15.5824 15.4665Z"
         fill="white"
      />
      <path
         d="M15.5824 44.2558C11.736 40.4381 9.5751 35.2601 9.5751 29.8611C9.5751 24.462 11.736 19.2841 15.5824 15.4664C19.4288 11.6487 24.6456 9.50395 30.0852 9.50395C35.5248 9.50395 40.7416 11.6487 44.588 15.4664L37.3366 22.6638C35.4134 20.7549 32.805 19.6825 30.0852 19.6825C27.3654 19.6825 24.757 20.7549 22.8338 22.6638C20.9106 24.5726 19.8302 27.1616 19.8302 29.8611C19.8302 32.5606 20.9106 35.1496 22.8338 37.0584L15.5824 44.2558Z"
         fill="white"
      />
   </svg>
);
export const Icon05 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#3BCEBD"
      />
      <path
         d="M16.0462 15.4665C17.9508 13.5761 20.2118 12.0766 22.7002 11.0536C25.1886 10.0305 27.8556 9.50399 30.5491 9.50399C33.2425 9.50399 35.9095 10.0305 38.3979 11.0536C40.8864 12.0766 43.1474 13.5761 45.0519 15.4665C46.9564 17.3568 48.4672 19.601 49.4979 22.0708C50.5287 24.5406 51.0592 27.1878 51.0592 29.8611C51.0592 32.5345 50.5287 35.1816 49.4979 37.6515C48.4672 40.1213 46.9564 42.3655 45.0519 44.2558L37.8005 37.0585C38.7528 36.1133 39.5081 34.9912 40.0235 33.7563C40.5389 32.5214 40.8041 31.1978 40.8041 29.8611C40.8041 28.5245 40.5389 27.2009 40.0235 25.966C39.5081 24.731 38.7528 23.609 37.8005 22.6638C36.8482 21.7186 35.7177 20.9689 34.4735 20.4574C33.2293 19.9458 31.8958 19.6826 30.5491 19.6826C29.2024 19.6826 27.8688 19.9458 26.6246 20.4574C25.3804 20.9689 24.2499 21.7186 23.2977 22.6638L16.0462 15.4665Z"
         fill="#8FD7FF"
      />
      <path
         d="M16.0462 44.2558C12.1999 40.4381 10.039 35.2601 10.039 29.8611C10.039 24.462 12.1999 19.2841 16.0462 15.4664C19.8926 11.6487 25.1094 9.50395 30.5491 9.50395C35.9887 9.50395 41.2055 11.6487 45.0519 15.4664L37.8005 22.6638C35.8773 20.7549 33.2689 19.6825 30.5491 19.6825C27.8293 19.6825 25.2208 20.7549 23.2977 22.6638C21.3745 24.5726 20.294 27.1616 20.294 29.8611C20.294 32.5606 21.3745 35.1496 23.2977 37.0584L16.0462 44.2558Z"
         fill="white"
      />
   </svg>
);
export const Icon06 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#87C66A"
      />
      <path
         d="M16.0462 15.4665C17.9508 13.5761 20.2118 12.0766 22.7002 11.0536C25.1886 10.0305 27.8556 9.50399 30.5491 9.50399C33.2425 9.50399 35.9095 10.0305 38.3979 11.0536C40.8864 12.0766 43.1474 13.5761 45.0519 15.4665C46.9564 17.3568 48.4672 19.601 49.4979 22.0708C50.5287 24.5406 51.0592 27.1878 51.0592 29.8611C51.0592 32.5345 50.5287 35.1816 49.4979 37.6515C48.4672 40.1213 46.9564 42.3655 45.0519 44.2558L37.8005 37.0585C38.7528 36.1133 39.5081 34.9912 40.0235 33.7563C40.5389 32.5214 40.8041 31.1978 40.8041 29.8611C40.8041 28.5245 40.5389 27.2009 40.0235 25.966C39.5081 24.731 38.7528 23.609 37.8005 22.6638C36.8482 21.7186 35.7177 20.9689 34.4735 20.4574C33.2293 19.9458 31.8958 19.6826 30.5491 19.6826C29.2024 19.6826 27.8688 19.9458 26.6246 20.4574C25.3804 20.9689 24.2499 21.7186 23.2977 22.6638L16.0462 15.4665Z"
         fill="white"
      />
      <path
         d="M16.0462 44.2558C12.1999 40.4381 10.039 35.2601 10.039 29.8611C10.039 24.462 12.1999 19.2841 16.0462 15.4664C19.8926 11.6487 25.1094 9.50395 30.5491 9.50395C35.9887 9.50395 41.2055 11.6487 45.0519 15.4664L37.8005 22.6638C35.8773 20.7549 33.2689 19.6825 30.5491 19.6825C27.8293 19.6825 25.2208 20.7549 23.2977 22.6638C21.3745 24.5726 20.294 27.1616 20.294 29.8611C20.294 32.5606 21.3745 35.1496 23.2977 37.0584L16.0462 44.2558Z"
         fill="white"
      />
   </svg>
);

export const Icon07 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0 8.72727C0 3.90733 3.93669 0 8.79285 0H51.658C56.5141 0 60.4508 3.90733 60.4508 8.72727V51.2727C60.4508 56.0927 56.5141 60 51.658 60H8.79285C3.93669 60 0 56.0927 0 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0 8.72727C0 3.90733 3.93669 0 8.79285 0H51.658C56.5141 0 60.4508 3.90733 60.4508 8.72727V51.2727C60.4508 56.0927 56.5141 60 51.658 60H8.79285C3.93669 60 0 56.0927 0 51.2727V8.72727Z"
         fill="#4C4A8D"
      />
      <path
         d="M15.5824 15.4665C17.4869 13.5761 19.7479 12.0766 22.2363 11.0536C24.7247 10.0305 27.3918 9.50399 30.0852 9.50399C32.7786 9.50399 35.4457 10.0305 37.9341 11.0536C40.4225 12.0766 42.6835 13.5761 44.588 15.4665C46.4926 17.3568 48.0033 19.601 49.0341 22.0708C50.0648 24.5406 50.5953 27.1878 50.5953 29.8611C50.5953 32.5345 50.0648 35.1816 49.0341 37.6515C48.0033 40.1213 46.4926 42.3655 44.588 44.2558L37.3366 37.0585C38.2889 36.1133 39.0443 34.9912 39.5596 33.7563C40.075 32.5214 40.3403 31.1978 40.3403 29.8611C40.3403 28.5245 40.075 27.2009 39.5596 25.966C39.0443 24.731 38.2889 23.609 37.3366 22.6638C36.3844 21.7186 35.2538 20.9689 34.0096 20.4574C32.7654 19.9458 31.4319 19.6826 30.0852 19.6826C28.7385 19.6826 27.405 19.9458 26.1608 20.4574C24.9166 20.9689 23.7861 21.7186 22.8338 22.6638L15.5824 15.4665Z"
         fill="#8FD7FF"
      />
      <path
         d="M15.5824 44.2558C11.736 40.4381 9.5751 35.2601 9.5751 29.8611C9.5751 24.462 11.736 19.2841 15.5824 15.4664C19.4288 11.6487 24.6456 9.50395 30.0852 9.50395C35.5248 9.50395 40.7416 11.6487 44.588 15.4664L37.3366 22.6638C35.4134 20.7549 32.805 19.6825 30.0852 19.6825C27.3654 19.6825 24.757 20.7549 22.8338 22.6638C20.9106 24.5726 19.8302 27.1616 19.8302 29.8611C19.8302 32.5606 20.9106 35.1496 22.8338 37.0584L15.5824 44.2558Z"
         fill="white"
      />
   </svg>
);
export const Icon08 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 61 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0.463867 8.72727C0.463867 3.90733 4.40056 0 9.25671 0H52.1218C56.978 0 60.9147 3.90733 60.9147 8.72727V51.2727C60.9147 56.0927 56.978 60 52.1218 60H9.25671C4.40056 60 0.463867 56.0927 0.463867 51.2727V8.72727Z"
         fill="#F3D431"
      />
      <path
         d="M16.0462 15.4665C17.9508 13.5761 20.2118 12.0766 22.7002 11.0536C25.1886 10.0305 27.8556 9.50399 30.5491 9.50399C33.2425 9.50399 35.9095 10.0305 38.3979 11.0536C40.8864 12.0766 43.1474 13.5761 45.0519 15.4665C46.9564 17.3568 48.4672 19.601 49.4979 22.0708C50.5287 24.5406 51.0592 27.1878 51.0592 29.8611C51.0592 32.5345 50.5287 35.1816 49.4979 37.6515C48.4672 40.1213 46.9564 42.3655 45.0519 44.2558L37.8005 37.0585C38.7528 36.1133 39.5081 34.9912 40.0235 33.7563C40.5389 32.5214 40.8041 31.1978 40.8041 29.8611C40.8041 28.5245 40.5389 27.2009 40.0235 25.966C39.5081 24.731 38.7528 23.609 37.8005 22.6638C36.8482 21.7186 35.7177 20.9689 34.4735 20.4574C33.2293 19.9458 31.8958 19.6826 30.5491 19.6826C29.2024 19.6826 27.8688 19.9458 26.6246 20.4574C25.3804 20.9689 24.2499 21.7186 23.2977 22.6638L16.0462 15.4665Z"
         fill="#393939"
      />
      <path
         d="M16.0462 44.2558C12.1999 40.4381 10.039 35.2601 10.039 29.8611C10.039 24.462 12.1999 19.2841 16.0462 15.4664C19.8926 11.6487 25.1094 9.50395 30.5491 9.50395C35.9887 9.50395 41.2055 11.6487 45.0519 15.4664L37.8005 22.6638C35.8773 20.7549 33.2689 19.6825 30.5491 19.6825C27.8293 19.6825 25.2208 20.7549 23.2977 22.6638C21.3745 24.5726 20.294 27.1616 20.294 29.8611C20.294 32.5606 21.3745 35.1496 23.2977 37.0584L16.0462 44.2558Z"
         fill="#393939"
      />
   </svg>
);
export const Icon09 = (
   <svg
      className="ml-3"
      width="60"
      height="60"
      viewBox="0 0 62 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M0.927734 8.72727C0.927734 3.90733 4.86443 0 9.72058 0H52.5857C57.4419 0 61.3786 3.90733 61.3786 8.72727V51.2727C61.3786 56.0927 57.4419 60 52.5857 60H9.72058C4.86443 60 0.927734 56.0927 0.927734 51.2727V8.72727Z"
         fill="#D3D3D3"
      />
      <path
         d="M0.927734 8.72727C0.927734 3.90733 4.86443 0 9.72058 0H52.5857C57.4419 0 61.3786 3.90733 61.3786 8.72727V51.2727C61.3786 56.0927 57.4419 60 52.5857 60H9.72058C4.86443 60 0.927734 56.0927 0.927734 51.2727V8.72727Z"
         fill="#3144F3"
      />
      <path
         d="M16.5101 15.4665C18.4146 13.5761 20.6757 12.0766 23.1641 11.0536C25.6525 10.0305 28.3195 9.50399 31.0129 9.50399C33.7064 9.50399 36.3734 10.0305 38.8618 11.0536C41.3502 12.0766 43.6112 13.5761 45.5158 15.4665C47.4203 17.3568 48.9311 19.601 49.9618 22.0708C50.9925 24.5406 51.523 27.1878 51.523 29.8611C51.523 32.5345 50.9925 35.1816 49.9618 37.6515C48.9311 40.1213 47.4203 42.3655 45.5158 44.2558L38.2644 37.0585C39.2166 36.1133 39.972 34.9912 40.4874 33.7563C41.0027 32.5214 41.268 31.1978 41.268 29.8611C41.268 28.5245 41.0027 27.2009 40.4874 25.966C39.972 24.731 39.2166 23.609 38.2644 22.6638C37.3121 21.7186 36.1816 20.9689 34.9374 20.4574C33.6932 19.9458 32.3597 19.6826 31.0129 19.6826C29.6662 19.6826 28.3327 19.9458 27.0885 20.4574C25.8443 20.9689 24.7138 21.7186 23.7615 22.6638L16.5101 15.4665Z"
         fill="#8FD7FF"
      />
      <path
         d="M16.5101 44.2558C12.6637 40.4381 10.5028 35.2601 10.5028 29.8611C10.5028 24.462 12.6637 19.2841 16.5101 15.4664C20.3565 11.6487 25.5733 9.50395 31.0129 9.50395C36.4525 9.50395 41.6694 11.6487 45.5158 15.4664L38.2644 22.6638C36.3412 20.7549 33.7327 19.6825 31.0129 19.6825C28.2931 19.6825 25.6847 20.7549 23.7615 22.6638C21.8383 24.5726 20.7579 27.1616 20.7579 29.8611C20.7579 32.5606 21.8383 35.1496 23.7615 37.0584L16.5101 44.2558Z"
         fill="white"
      />
   </svg>
);
