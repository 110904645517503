import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "../pages/landing/home/LandingPage";

const LandingRoutes = () => {
  const routes = [
    { url: "/", element: <LandingPage  /> },
  ];

  return (
    <Router basename="/">
        <Routes>
          {routes.map((data, i) => (
            <Route key={i} exact path={`/${data.url}`} element={data.element} />
          ))}
        </Routes>
    </Router>
  );
};

export default LandingRoutes;
